import { COOKIES } from "./constants";

export function getCookie(cookieName: string): string {
	if (document.cookie.length > 0) {
		let cookieStart = document.cookie.indexOf(cookieName + "=");

		if (cookieStart !== -1) {
			cookieStart = cookieStart + cookieName.length + 1;
			let cookieEnd = document.cookie.indexOf(";", cookieStart);
			if (cookieEnd === -1) {
				cookieEnd = document.cookie.length;
			}

			return document.cookie.substring(cookieStart, cookieEnd);
		}
	}

	return null;
}

export function setCookieWithExpiry(cookieName: string, cookieValue: string, expirationInDays: number) {
	placeCookie(cookieName, cookieValue, expirationInDays * 86400000);
}

export function deleteTealiumCookie() {
	document.cookie = `${COOKIES.TEALIUM}="";max-age=-1;path=/;domain=${window.JetSmart.CookieDomain}`;
}

export function deletePcraLoginCookie() {
	document.cookie = `${COOKIES.PERU_COMPRA_LOGIN}="";max-age=-1;path=/;domain=${window.JetSmart.CookieDomain}`;
}

function placeCookie(cookieName: string, cookieValue: string, expirationInMs: number) {
	const newDate = new Date();
	newDate.setTime(newDate.getTime() + expirationInMs);
	const expirationDate = "expires=" + newDate.toUTCString();
	document.cookie = `${cookieName}=${cookieValue};${expirationDate};path=/;domain=${window.JetSmart.CookieDomain}`;
}
