import { LocalizedString } from "Shared/models/ApiStationSettings";
import smoothscroll from "smoothscroll-polyfill";
import { CLASS_NAMES } from "./ClassNames";
import { BRASILIAN_CULTURE_CODE, USA_CULTURE_CODE } from "./constants";
import DomCrawlingHelper from "./DomCrawlingHelper";

export interface FormResources {
	fieldRequired: string;
	emailNotValid: string;
	validateCaptcha: string;
	formatInvalid: string;
	greaterOrEqual: string;
}

window.addEventListener("load", () => {
	(window as any).__forceSmoothScrollPolyfill__ = true;
	smoothscroll.polyfill();
});

export function getRequestUrl(queryParams: any): string {
	let first = true;
	let relativeUrl = "";
	for (const queryValue in queryParams) {
		if (queryParams[queryValue] !== null) {
			const value = queryParams[queryValue];
			const valueString =
				typeof value === "object"
					? getSerializedObject(value)
					: queryValue + "=" + encodeURIComponent(queryParams[queryValue]);

			if (first) {
				relativeUrl += "?" + valueString;
				first = false;
			} else {
				relativeUrl += "&" + valueString;
			}
		}
	}
	return relativeUrl;
}

function getSerializedObject(object: any): string {
	let value = "";
	let first = true;

	for (const property in object) {
		if (object.hasOwnProperty(property)) {
			const prop = object[property];
			if (first && prop !== undefined) {
				value += property + "=" + encodeURIComponent(prop);
				first = false;
			} else if (prop !== undefined) {
				value += "&" + property + "=" + encodeURIComponent(prop);
			}
		}
	}

	return value;
}

export function toBoolean(value: string | boolean | undefined): boolean {
	if (!value) {
		return false;
	}
	return (typeof value !== "boolean" && value.toLowerCase() === "true") || (typeof value === "boolean" && value);
}

export function getCoords(elem: HTMLElement) {
	// crossbrowser version
	const box = elem.getBoundingClientRect();

	const body = document.body;
	const docEl = document.documentElement;

	const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
	const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

	const clientTop = docEl.clientTop || body.clientTop || 0;
	const clientLeft = docEl.clientLeft || body.clientLeft || 0;

	const top = box.top + scrollTop - clientTop;
	const left = box.left + scrollLeft - clientLeft;

	return { top: Math.round(top), left: Math.round(left) };
}

export function revealDelayedElements(container: HTMLElement): void {
	if (!container) {
		container = document.body;
	}
	container.classList.remove(CLASS_NAMES.delayed);
	const delayedElements = DomCrawlingHelper.getArrayOfClass(container, CLASS_NAMES.delayed);
	if (delayedElements.length > 0) {
		delayedElements.forEach((e) => e.classList.remove(CLASS_NAMES.delayed));
	}
}

export function scrollToFirstError(): void {
	const errors = Array.from(document.querySelectorAll(".error, .field-error")) as HTMLElement[];
	const firstError = errors.find((err) => err.offsetHeight > 0);

	if (firstError) {
		const topOfElement = getCoords(firstError).top - 150;
		window.scroll({ top: topOfElement, behavior: "smooth" });
	}
}

export const getParsedProperty = <T>(data: any): T => JSON.parse(data as unknown as string) as T;

export function getLocalizedStringValue(value: LocalizedString, culture: string): string {
	if (!value) {
		return "";
	}

	if (culture.toLowerCase() === BRASILIAN_CULTURE_CODE && value.Pt) {
		return value.Pt;
	}

	if (culture.toLowerCase() === USA_CULTURE_CODE && value.En) {
		return value.En;
	}

	return value.Es;
}

export function clone<T>(obj: T): T {
	return JSON.parse(JSON.stringify(obj));
}

export function isMobileResolution(): boolean {
	return getScreenWidth() < 768;
}

export function isTabletOrMobileResolution(): boolean {
	return getScreenWidth() < 1024;
}

function getScreenWidth(): number {
	const w = window;
	const doc = document;
	const e = doc.documentElement;
	const g = doc.getElementsByTagName("body")[0];
	const x = w.innerWidth || e.clientWidth || g.clientWidth;

	return x;
}

export function removeTrailingSlash(str: string): string {
	return str.replace(/\/$/, "");
}
